import React from "react"
import Layout from "../components/layout"
import ReactPlayer from "react-player/lazy";
import {graphql, StaticQuery} from "gatsby";
import "../styles/not-found.scss";

const NotFoundPage = ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query PageNotFoundQuery {
          wp {
            themeGeneralSettings {
              themeSettings {
              notfoundtitle
              notfoundsubtitle
              notfoundbutton {
                target
                url
                title
                }
              notfoundvideo
              }
            }
          }
        }
      `}
      render={data => {
        // console.log('404', data);
        const content = data?.wp?.themeGeneralSettings?.themeSettings

        return (

          <Layout location={location}>
            <div className="page-wrapper not-found-wrapper">
              <div className="container">
                <div className="overlay"></div>
                {content?.notfoundvideo &&
                <ReactPlayer
                  className='react-player'
                  loop={true}
                  volume="0"
                  muted={true}
                  playing={true}
                  playsinline={true}
                  url={content?.notfoundvideo}
                  width="100%"
                  height="100%"
                />
                }
                <div className="text-content">
                  <h2 className="title">{content?.notfoundtitle}</h2>
                  <h4 className="subtitle">{content?.notfoundsubtitle}</h4>
                  {content?.notfoundbutton &&
                  <a className="button" href={content?.notfoundbutton?.url}>{content?.notfoundbutton?.title}</a>
                  }

                </div>
              </div>
            </div>
          </Layout>
        )
      }}
    />
  )
}

export default NotFoundPage
